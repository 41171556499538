<template>
  <v-row justify="center" v-if="dialogLoadingDebts">
    <v-dialog
      v-show="false"
      v-model="dialogLoadingDebts"
      persistent
      max-width="310"
    >
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <img src="@/assets/img/svg/aguarde.svg" />
        </v-card-title>
        <v-card-text>
          <p class="qj-card-text">
            <b>Aguarde um instante...</b>
            <br />enquanto buscamos seus débitos.
          </p>
          <v-progress-circular
            indeterminate
            class="qj-color-primary"
            :size="50"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { createTagManagerEvent } from "@/utils";
import util from "@/assets/js/util";
import Deal from "@/modules/deal";
import environment from "@/assets/js/environment";

export default {
  props: ["dialogLoadingDebts"],
  data: () => ({
    attempts: 0,
    // Mede o tempo de carregamento das dívidas
    loadingStart: 0,
    QUERY_TASK_MAX_ATTEMPTS: null,
  }),
  created() {
    this.QUERY_TASK_MAX_ATTEMPTS = environment.queryTaskMaxAttempts;
  },
  watch: {
    dialogLoadingDebts(newVal, oldVal) {
      if (!oldVal && newVal) this.searchDebt();
    },
  },
  methods: {
    ...mapActions("websocket", ["joinUserRoom"]),
    ...mapActions("negotiation", [
      "ActionsMe",
      "ActionsTask",
      "ActionsDeals",
      "getSyncedDeals",
      "getPrePayments",
      "getAgreements",
      "getSyncs",
      "getDeals",
      "openDialogCreditorsOff",
    ]),
    ...mapActions("creditor", ["fetchCreditorProfiles"]),
    ...mapActions("contact", ["requestNotification"]),
    ...mapMutations("negotiation", [
      "setLoadingPriorityTask",
      "setLoadingTasks",
      "setAllDeals",
      "setSyncFinished",
      "forceSyncToFinish",
      "setNonSyncedDeals",
    ]),
    async searchDebt() {
      this.loadingStart = Math.round(performance.now());
      let continueTo = null;
      await this.fetchCreditorProfiles();
      await this.ActionsMe();
      this.joinUserRoom();
      await this.getPrePayments();
      await this.getSyncs();
      await this.getSyncedDeals();
      await this.getAgreements();
      this.requestNotification();
      if (this.$route.query.continue) {
        continueTo = decodeURIComponent(this.$route.query.continue);
        continueTo = continueTo.replace(window.location.origin, "");
      }
      this.$router.push({
        name: "Deals",
        query: { persistent: true, continue: continueTo },
      });
      await this.$sleep(20000);
      if (this.creditorsToSync.length > 0) {
        await this.getSyncedDeals();
      }
      this.validateDealsAndSync();

      if (this.customer?.id) {
        window.dataLayer.push({
          event: "loggedUser",
          customerId: this.customer.id,
        });

        if (window.hj) {
          window.hj("identify", this.customer.id);
        }
      }
    },
    formatMoment(finish, start) {
      const duration = this.$moment.duration(finish - start);
      return this.$moment.utc(duration.as("milliseconds")).format("mm:ss.SSS");
    },
    dialog() {
      if (this.dialogLoadingDebts) this.searchDebt();
    },
    continueTo(deal) {
      let continueTo = null;
      if (this.$route.query.continue) {
        continueTo = decodeURIComponent(this.$route.query.continue);
        continueTo = continueTo.replace(window.location.origin, "");
      }
      if (continueTo?.includes("negociacao")) {
        let encodedDealId = this.$route.query.continue
          ?.split("id=")[1]
          ?.split("&")?.[0];
        let dealId = util.base64Decode(encodedDealId ?? "");
        if (continueTo && dealId == deal?.[0]?.id) {
          this.$router.push(continueTo).catch(() => {});
        }
      }
      if (continueTo?.includes("negociacao/pagamento")) {
        let encodedProposalId = this.$route.query.continue
          ?.split("id=")[1]
          ?.split("&")?.[0];
        let proposalId = util.base64Decode(encodedProposalId ?? "");
        let includesProposal = deal?.[0]?.proposals.some(
          (proposal) => proposal.id == proposalId
        );
        if (continueTo && includesProposal) {
          this.$router.push(continueTo).catch(() => {});
        }
      }
      if (continueTo?.includes("acordo")) {
        let encodedActiveProposalId = this.$route.query.continue
          ?.split("id=")[1]
          ?.split("&")?.[0];
        let activeProposalId = util.base64Decode(encodedActiveProposalId ?? "");
        if (continueTo && activeProposalId == deal?.[0]?.active_proposal_id) {
          this.$router.push(continueTo).catch(() => {});
        }
      }
    },
    callDialogCreditorsOffline() {
      this.openDialogCreditorsOff();
    },
    validateDealsAndSync() {
      const syncsNotFinished = this.creditorsToSync;
      const dealsOnScreen = Object.entries(this.debits).map((item) => item[1]);
      if (syncsNotFinished && dealsOnScreen.length === 0) {
        this.callDialogCreditorsOffline();
        syncsNotFinished.forEach((sync) => {
          createTagManagerEvent({
            event: "userWithNoVisibleDebitsAndSyncNotFinished",
            creditor_id: sync.id,
            creditor_name: sync.name,
          });
        });
      } else if (syncsNotFinished.length > 0 && dealsOnScreen.length > 0) {
        syncsNotFinished.forEach((sync) => {
          createTagManagerEvent({
            event: "syncTakingTooLong",
            creditor_id: sync.id,
            creditor_name: sync.name,
          });
        });
      }

      this.forceSyncToFinish();
    },
  },
  computed: {
    ...mapGetters("negotiation", {
      deals: "getDeals",
      customer: "getMe",
      creditorsToSync: "getCreditorsToSync",
    }),
    debits() {
      let deals = [];
      this.deals
        .filter((deal) => {
          return Deal.isNegotiable(deal);
        })
        ?.forEach((i) => {
          if (deals[i?.creditor?.profile_key]) {
            deals[i?.creditor?.profile_key]?.push(i);
          } else {
            deals[i?.creditor?.profile_key] = [i];
          }
        });
      return deals;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  button {
    width: 25em;
  }
}

.v-card.v-sheet {
  border-radius: 10px !important;
}
</style>
